<template>
  <div class='detail'>
    <div class="title">
      <p><span>{{info.unitName}}</span>-<span>{{info.roomName}}</span></p>
      <div @click="$router.go(-1)">返回</div>
    </div>
    <el-tabs v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="告警图片" name="first" v-if="warning.length>0">
        <template slot="label">
          <el-badge :value="warning.length" class="item">
            告警图片
          </el-badge>
        </template>
        <el-row :gutter="15">
          <vue-scroll :ops="ops">
            <el-col :span="6" v-for="(el,index) in warning" :key="index">
              <div v-if="el">
                <p @click="checkimg(el.picturePath)">
                  <i class="el-icon-zoom-in"></i>
                  查看
                </p>
                <img :src="el.picturePath.indexOf('https') !== -1?el.picturePath:url === 'scdisinfection.terabits.cn'?`http://${url}${el.picturePath.replace('D:', '').replace('\\', '/')}`:`http://${url}${el.picturePath}`" alt="">
              </div>
            </el-col>
          </vue-scroll>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="非告警图片" name="second" v-if="nowarning.length>0">
        <template slot="label">
          <el-badge :value="nowarning.length" class="item">
            非告警图片
          </el-badge>
        </template>
        <el-row :gutter="10">
          <vue-scroll :ops="ops">
            <el-col :span="6" v-for="(el,index) in nowarning" :key="index">
              <div v-if="el.picturePath">
                <p @click="checkimg(el.picturePath)">
                  <i class="el-icon-zoom-in"></i>
                  查看
                </p>
                <img :src="el.picturePath.indexOf('https') !== -1?el.picturePath:url === 'scdisinfection.terabits.cn'?`http://${url}${el.picturePath.replace('D:', '').replace('\\', '/')}`:`http://${url}${el.picturePath}`" alt="">
              </div>
            </el-col>
          </vue-scroll>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <!-- 查看图片弹框 -->
    <el-dialog title="图片详情" :lock-scroll="false" :visible.sync="dialogVisible" width="40%">
      <div v-if="zoomin">
        <img :src="zoomin.indexOf('https') !== -1?zoomin:url === 'scdisinfection.terabits.cn'?`http://${url}${zoomin.replace('D:', '').replace('\\', '/')}`:`http://${url}${zoomin}`" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 接受的参数
      info: {},
      // 选中tab
      activeName: 'first',
      // 数据
      detailList: [],
      // 告警数据
      warning: {},
      // 非告警数据
      nowarning: {},
      // 滚动条参数
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#999999', //滚动条颜色
          opacity: 0.6, //滚动条透明度
          'overflow-x': 'hidden',
          minSize: 0.1
        },
        videoData: [],
      },
      // 查看放大图片路径
      zoomin: null,
      dialogVisible: false,
      loading: false,

    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['url'])
  },
  methods: {
    // 初始化数据
    init () {
      // const str = 'D:\\detection\\resource\\90\\from\\2022-04-11\\shangcheng1649630599202.jpg'
      // console.log(`http://${this.url}${str.replace('D:', '').replace('\\', '/')}`, 33333333)
      this.info = JSON.parse(this.$Base64.decode(this.$route.query.info))
      const { list } = this.info
      this.warning = list.filter(el => el.status)
      this.nowarning = list.filter(el => !el.status)

      //   this.warning = this.detailList.filter(el => el.isAlarm).length > 0 ? this.detailList.filter(el => el.isAlarm)[0] : {}
      //   this.nowarning = this.detailList.filter(el => !el.isAlarm).length > 0 ? this.detailList.filter(el => !el.isAlarm)[0] : {}
      //   this.activeName = this.warning.sum ? 'first' : 'second'
      //   this.loading = false
      // })

      // console.log(this.warning, this.nowarning)
    },
    handleClick (val) {
      console.log(val)
    },
    // 查看图片
    checkimg (val) {
      // console.log(val)
      this.zoomin = val
      this.$nextTick(() => {
        this.dialogVisible = true
      })

    }
  }
};
</script>

<style scoped lang="scss">
.detail {
  padding-bottom: 39px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #30c6fe;
    color: #fff;
    padding: 12px 20px;
    border-radius: 10px;
    margin: 20px 0;
    font-size: 16px;
    div {
      border: 1px solid #fff;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  ::v-deep .el-tabs {
    //   // cursor: pointer;
    height: 71vh;
    .el-tabs__item {
      color: rgb(255, 255, 255);
    }
    .el-tabs__item {
      height: 50px;
      line-height: 50px;
      .el-badge__content.is-fixed {
        top: 12px;
      }
    }

    .el-tabs__nav-wrap {
      padding: 0 20px;
    }
  }
  // ::v-deep .imgtab {
  //   .el-tabs__item {
  //     height: 80px;
  //     display: flex;
  //     align-items: center;
  //   }
  //   .is-active {
  //     color: #409eff;
  //   }
  //   .el-tabs__nav-wrap {
  //     padding: 0;
  //   }
  .el-row {
    height: 620px;
    padding: 0 10px 0 20px;
    .el-col {
      margin-bottom: 10px;

      div {
        width: 100%;
        height: 100%;
        position: relative;
        > p {
          position: absolute;
          bottom: 0;
          right: 0;
          color: white;
          background: rgba(0, 0, 0, 0.3);
          padding: 5px 10px;
          border-radius: 20px 0 0 20px;
          cursor: pointer;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // }
  ::v-deep .el-dialog__wrapper {
    .el-dialog__body {
      > div {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
